import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import PageModal from '@/components/PageModal';
import { verseFetcher } from '@/utils/verseFetcher';
import { isExternalURLCheck } from '@/utils/isExternalURLCheck';
import { centerFootnote } from '@/utils/centerFootnote';

export default function ClickHandler({ className, children }) {
    const router = useRouter();
    // const { request } = router;

    const clickHandler = e => {
        const target = e?.target;
        const classList = target?.classList;
        const href = target.href || false;
        let url = target.getAttribute('href') || target.parentNode.getAttribute('href');

        if (classList?.contains('modal-link')) {
            // This is not currently in use and may or may not be functional.
            const pathname = target?.pathname;
            setShowModal(true);
            setPathname(pathname);
            return null;
        } else if (classList?.contains('verse-link')) {
            // For future Bible API but not currently in use.
            const verse = target.dataset.verse;
            const tooltipId = target.dataset.tooltipId;
            let id = target.id;
            let tooltip = document.querySelector(`#${tooltipId}`);
            let classList = tooltip.classList;
            let isShowing = classList.contains('show');

            isShowing ? classList.remove('show') : classList.add('show');

            if (!isShowing && tooltip.innerHTML === '') {
                tooltip.innerHTML = 'Loading...';
                verseFetcher(verse).then(results => {
                    let result = results.map(result => {
                        return result?.replace(verse, `<strong>${verse}</strong>`) + '<br><br>';
                    });
                    const resultJoin = result.join('');
                    let link = `<a href="https://www.esv.org/${verse}/" target="_blank">Read full</a>`;
                    tooltip.innerHTML = resultJoin + link;
                });
            }
        } // Showing Bible verse tooltip. Not currently in use
        else if (target && target.classList.contains('verse-tooltip')) {
            let classList = target.classList;
            let isShowing = classList.contains('show');
            isShowing ? classList.remove('show') : classList.add('show');
        } else if (url?.startsWith('#') && url?.length > 1) {
            e.preventDefault();
            if (url?.startsWith('#_')) {
                // For footnotes/endnotes
                centerFootnote(url);
            } else {
                // For scrolling from anchor tag link matching id on same page
                const urlSplit = url.split('#');
                const anchorTag = '#' + urlSplit[1];
                let el = document.querySelector(anchorTag);
                if (!el) return null;
                el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        } else {
            const link = href || url;
            if (link?.startsWith('http')) {
                e.preventDefault();
                if (isExternalURLCheck(link) || target.target === '_blank') {
                    window.open(link, '_blank');
                } else {
                    router.push(link);
                }
            } else {
                // most link clicks will run through this
                return true;
            }
        }
    };

    const [showModal, setShowModal] = useState(false);
    const [pathname, setPathname] = useState(null);

    useEffect(() => {
        const html = document.getElementsByTagName('html');

        // if (showModal) {
        //     html[0].classList.remove('scroll-smooth');
        //     html[0].classList.add('overflow-hidden');
        // } else {
        //     html[0].classList.add('scroll-smooth');
        //     html[0].classList.remove('overflow-hidden');
        // }

        return () => {
            html;
        };
    }, [showModal]);

    return (
        <div onClick={clickHandler} className={className}>
            {children}
            {showModal && pathname && <PageModal targetUri={pathname} open={showModal} onClose={() => setShowModal(false)} />}
        </div>
    );
}
