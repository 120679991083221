export const centerFootnote = url => {
    url = url.slice(1);
    let el = document.querySelector('a[name=' + url + ']');
    el.classList.add('endnote-link');
    let arg = { behavior: 'smooth', block: 'center' };
    let offset = 100;
    if (!el) {
        let canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

        el = document.getElementById(url);
        let elPos = el.getBoundingClientRect().top;
        let offsetPos = elPos + window.pageYOffset;
        if (canUseDOM) {
            offsetPos = offsetPos - offset;
            el.scrollIntoView({
                block: 'start',
                behavior: 'smooth'
            });

            window.scrollTo({
                top: offsetPos,
                behavior: 'smooth'
            });
        }
        return null;
    } else {
        el.scrollIntoView(arg);
    }
    el.parentElement.classList.add('footnote-endnote-linking');
    setTimeout(function () {
        el.parentElement.classList.remove('footnote-endnote-linking');
    }, 2000);
};
