import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

const LayoutTypes = {
    Page: dynamic(() => import('./Page')),
    GivePages: dynamic(() => import('./Give')),
    Post: dynamic(() => import('./Post')),
    Category: dynamic(() => import('./Category')),
    Staff: dynamic(() => import('./Staff/Staff')),
    ContentType: dynamic(() => import('./Staff/index')),
    ErrorPage: dynamic(() => import('./Error404')),
    BranchLocations: dynamic(() => import('./BranchLocations/index'))
};

export default function Layouts({ data, layout, pageHeaderSettings = null, children = null }) {
    // Choosing which layout to load based off of __typename. If the __typename doesn't match, we are loading 404 page.
    const Layout = LayoutTypes[layout] || LayoutTypes['ErrorPage'];
    const { categoryPageHeaderSettings } = data || {};
    const router = useRouter();

    useEffect(() => {
        // This is for redirecting 404 Give pages to the main Give page.
        if (router?.asPath.startsWith('/give') && layout === 'ErrorPage') {
            const asPath = router?.asPath;
            const params = asPath.split('?')[1];

            router.push(`/give${params ? '?' + params : ''}`);
        }
    }, [router, layout]);

    if (typeof layout === 'string') {
        return (
            <Layout layoutData={data || {}} pageHeaderSettings={pageHeaderSettings || categoryPageHeaderSettings}>
                {children}
            </Layout>
            // </GlobalAppThemeProvider>
        );
    }
}
