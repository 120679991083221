export default function imgixLoader({ src, width, quality }) {
    // Check if src is a full URL or a relative path
    let fileName;
    try {
        const url = new URL(src); // If it's a full URL, this will work
        fileName = url.pathname; // Extracts only the path (including the file name)
    } catch (error) {
        // If src is a relative path, return it as is
        return src;
    }

    // Proceed with imgix URL construction
    const imgixUrl = new URL(`https://jfj.imgix.net${fileName}`);
    const params = imgixUrl.searchParams;
    params.set('auto', 'format');
    params.set('fit', 'max');
    params.set('w', width.toString());

    if (quality) {
        params.set('q', quality.toString());
    }

    return imgixUrl.href;
}
