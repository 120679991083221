import { AxisYLocation, AxisXLocation } from '../types/StyleTypes';

export const locationAsPosition = ({ axisX, axisY }: { axisX?: AxisXLocation; axisY?: AxisYLocation }): string => {
    let position = axisY === 'center' ? 'top 50%' : `${axisY} 0`;
    return axisX === 'center' ? `${position} left 50%` : `${position} ${axisX} 0`;
};

export function getFlexibleColumnWidth(width: string, useCustomWidth: boolean) {
    return {
        'md:w-1/12': width === 'span-1' && useCustomWidth,
        'md:w-2/12': width === 'span-2' && useCustomWidth,
        'md:w-3/12': width === 'span-3' && useCustomWidth,
        'md:w-4/12': width === 'span-4' && useCustomWidth,
        'md:w-5/12': width === 'span-5' && useCustomWidth,
        'md:w-6/12': width === 'span-6' && useCustomWidth,
        'md:w-7/12': width === 'span-7' && useCustomWidth,
        'md:w-8/12': width === 'span-8' && useCustomWidth,
        'md:w-9/12': width === 'span-9' && useCustomWidth,
        'md:w-10/12': width === 'span-10' && useCustomWidth,
        'md:w-11/12': width === 'span-11' && useCustomWidth,
        'md:w-12/12': width === 'span-12' && useCustomWidth
    };
}

export function getTextAlignmentHeader(alignment: string) {
    if (alignment === 'left') return 'md:text-left md:items-start';
    else if (alignment === 'right') return 'md:text-right md:items-end';
}
