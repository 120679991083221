import { NextPage, GetStaticProps, GetStaticPaths } from 'next';
import dynamic from 'next/dynamic';
// import Link from 'next/link';
import { GET_PAGE_POST_URIS } from '../graphql/pages';
import { addApolloState, initializeApollo } from '@/hooks/apolloClient';
import {
    Get_Menu_By_LocationDocument,
    ThemeGeneralSettingsDocument,
    NodeByUriDocument,
    useNodeByUriQuery,
    NodeByUriQueryVariables,
    GetGravityFormDocument
} from '../generated/graphql';
import { addTailwindClasses } from '@/utils/addTailwindClasses';
import Layouts from '@/layouts/index';
import ClickHandler from '@/components/ClickHandler';
import { stripTrailingSlash } from '@/utils/stripTrailingSlash';
import isProduction from '@/utils/isProduction';

const Dev = dynamic(() => import('@/components/Dev'));

type PageProps = {
    pageQueryVariables: NodeByUriQueryVariables;
    vercel_env: string;
};

const WildcardPage: NextPage<PageProps> = ({ pageQueryVariables, vercel_env }) => {
    // Our main query for pages: query data found in src/graphql/nodebyUri.js
    const { loading, error, data } = useNodeByUriQuery({
        variables: pageQueryVariables,
        fetchPolicy: 'cache-only',
        ssr: true
    });

    // Checking if any data was returned from the query
    const content = data?.nodeByUri || null;
    const havePosts = Boolean(content);
    // Choosing which layout to load in src/pages/layouts/index.js based off of __typename
    const layout = havePosts ? data?.nodeByUri?.__typename : 'ErrorPage';

    // console.log(layout, 'layout');

    if (loading) return <p>Loading...</p>;
    if (error) return <p>An error has occurred</p>;

    return (
        // Our main wrapper. We are capturing any clicks in src/components/ClickHandler
        <ClickHandler className="bg-white dark:bg-darkModeBg dark:text-white">
            {vercel_env !== 'production' && <Dev />}
            <Layouts data={content} layout={layout} />
        </ClickHandler>
    );
};

export const getStaticProps: GetStaticProps = async function (ctx) {
    // Contains the info for dynamic routes
    const { params } = ctx;
    // This is how we are adding Tailwind classes we have in WP to our safelist
    if (process.env.NODE_ENV === 'development') {
        addTailwindClasses();
    }

    // const preview = ctx?.preview || false;
    // const previewData = ctx?.previewData || null;
    // const pagePreview = preview && previewData;

    const uri = (params.uri as string[]) || null;
    const apolloClient = initializeApollo();
    let pageUri = uri ? uri.join('/') : '/';
    let variables;

    const isNum = val => {
        return !isNaN(val);
    };

    // Buya: can you describe what the purpose of these PER_PAGE sections are?
    const pageNo = +uri?.pop();
    const previousItem = uri?.pop();
    const PER_PAGE = +process.env.NEXT_PUBLIC_PER_PAGE;
    let offset = 0;

    variables = {
        uri: pageUri === '/' ? '/' : `/${pageUri.toLowerCase()}`,
        offset: 0,
        size: PER_PAGE
    };

    if (isNum(pageNo)) {
        if (previousItem === 'page') {
            if (+pageNo === 1 || !pageNo) offset = 0;
            else if (+pageNo === 2) offset = PER_PAGE;
            else offset = PER_PAGE + (pageNo - 2) * PER_PAGE;

            variables = {
                uri: pageUri.toLowerCase(),
                offset,
                size: PER_PAGE
            };
        }
    }

    let data;
    let returnObj = null;

    data = await apolloClient.query({ query: NodeByUriDocument, variables }).catch(err => console.error('NodeByUriDocument ERROR', err, variables));

    if (!data?.data?.nodeByUri) {
        returnObj = {
            notFound: true,
            revalidate: 1
        };
    }

    await Promise.all([
        await apolloClient.query({ query: ThemeGeneralSettingsDocument }).catch(err => console.error('ThemeGeneralSettingsDocument ERROR', err)),
        await apolloClient
            .query({ query: Get_Menu_By_LocationDocument, variables: { location: 'MEGALEFT' } })
            .catch(err => console.error('Get_Menu_By_LocationDocument ERROR', err)),

        await apolloClient.query({ query: GetGravityFormDocument, variables: { id: 10, idType: 'DATABASE_ID' } }).catch(err => console.error('GetGravityFormDocument ERROR', err))
    ]);

    // console.log('pageNo', pageNo, 'previousItem', previousItem, 'pageUri', pageUri, 'variables', variables);

    // Redirect to correct URI if needed
    if (!isNum(pageNo) && previousItem !== 'page') {
        try {
            const data = apolloClient.readQuery({
                query: NodeByUriDocument,
                variables
            });

            if (!data) {
                return {
                    notFound: true,
                    revalidate: 1
                };
            }

            const uriInDB = stripTrailingSlash(data?.nodeByUri?.uri);
            const requestedUri = stripTrailingSlash(`/${pageUri}`);

            if (uriInDB && uriInDB !== requestedUri) {
                console.log('redirecting to correct URI', uriInDB, requestedUri, data?.nodeByUri?.uri);

                return {
                    redirect: {
                        destination: uriInDB,
                        permanent: true
                    }
                };
            }
        } catch (error) {
            console.error('Redirect to correct URI query has error in [URI.js]', error);
        }
    }

    const production = isProduction();
    const preview = process.env.VERCEL_ENV === 'preview';
    const vercel_env = process.env.VERCEL_ENV;

    returnObj = production
        ? {
              props: {
                  pageQueryVariables: variables,
                  vercel_env
              },
              revalidate: 60 // Revalidate is the amount of seconds after which a page would check for updated data.
          }
        : {
              props: {
                  pageQueryVariables: variables,
                  vercel_env
              },
              revalidate: preview ? 5 : 1
          };

    return addApolloState(apolloClient, returnObj);
};

export const getStaticPaths: GetStaticPaths = async function () {
    const apolloClient = initializeApollo();

    // Returning no statically generated pages if we are not on jewsforjesus.org
    if (process.env.VERCEL_ENV !== 'production') {
        return {
            paths: [{ params: { uri: [] } }],
            fallback: 'blocking'
        };
    }

    const { data } = await apolloClient.query({
        query: GET_PAGE_POST_URIS
    });
    // const [getData, { loading, error, data }] = useLazyQuery(GET_PAGE_POST_URIS);
    // getData();

    // Gathering data for all pages we are statically generating
    const pages = data?.pages?.nodes || [];
    const posts = data?.posts?.nodes || [];
    const categories = data?.categories?.nodes || [];
    const staff = data?.allStaff?.nodes || [];
    const branches = data?.allBranchLocations?.nodes || [];
    const mergedUris = [...pages, ...posts, ...categories, ...staff, ...branches];
    const uris = mergedUris?.map(page => page?.uri.split('/').filter(Boolean));

    const paths = (uris || []).map(uri => {
        return { params: { uri } };
    });

    return {
        paths,
        fallback: 'blocking' // More info: https://nextjs.org/docs/api-reference/data-fetching/get-static-paths#fallback-blocking
    };
};

export default WildcardPage;
