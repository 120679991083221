export const verseFetcher = async (verse) => {
    let url = `https://api.esv.org/v3/passage/text/?q=${verse}`;
    let finalPassages = [];
    const result = await fetch(url, {
        headers: new Headers({
            Authorization: 'Token bf0f4ac37a5f14dac22f530ce63b0ef310b0e4bd',
            Accept: 'application/json'
        }),
        cache: 'force-cache'
    })
        .then((response) => response.json())
        .then((data) => {
            return data.passages;
        });
    return result;
};
